import axios from '@crema/services/axios';
import type { AxiosResponse } from 'axios';

const jwtAxios = axios.create({
  // baseURL: 'https://api.advengg.ae/', 
  baseURL: "https://api.projecttrackai.com/",
  // baseURL:"http://localhost:5000/",
  headers: {
    'Content-Type': 'application/json',
  },
});

jwtAxios.interceptors.response.use(
  (res: AxiosResponse<any, any>) => res,
  (err: any) => {
    if (err.response && err.response.data.message === 'Failed to authenticate token') { // Unauthorized
      console.log('Token expired or invalid. Logging out user...');
      localStorage.clear();
      window.location.href = '/signin';
    }
    if (err.response && err.response.data.message === 'User is blocked for now') { // Unauthorized
      console.log('User is blocked for now');
      localStorage.clear();
      window.location.href = '/signin';
    }
    return Promise.reject(err);
  }
);

export const setAuthToken = (token?: string) => {
  if (token) {
    jwtAxios.defaults.headers.common.Authorization = token;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common.Authorization;
    localStorage.removeItem('token');
  }
};


export default jwtAxios;
