import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import jwtAxios, { setAuthToken } from './index';
import { useInfoViewActionsContext } from '@crema/context/AppContextProvider/InfoViewContextProvider';

interface JWTAuthContextProps {
  user: any | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
}

interface JWTAuthActionsProps {
  // signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
  updateUser:any
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  // signUpUser: () => {},
  signInUser: () => { },
  logout: () => { },
  updateUser:()=>{}
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const [firebaseData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: false,
  });

  const infoViewActionsContext = useInfoViewActionsContext();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem('token');
      const userData = localStorage.getItem('userData');

      if(token && userData){
        setJWTAuthData({
          user:userData,
          isLoading:false,
          isAuthenticated:true
        })
      }else {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        localStorage.clear();
        return;
      }
  
      setAuthToken(token);
  
      // Parse the userData JSON string back into an object
      const parsedUserData = JSON.parse(userData);
  
      setJWTAuthData({
        user: parsedUserData,
        isLoading: false,
        isAuthenticated: true,
      });
    };
  
    getAuthUser();
  }, []);
  
  const signInUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    infoViewActionsContext.fetchStart();
    try {
      const { data } = await jwtAxios.post('authentication', { email, password });
      localStorage.setItem('token', data.token);
      localStorage.setItem('userData', JSON.stringify(data.user));

      setAuthToken(data.token);
      // const res = await jwtAxios.get('/auth');
      setJWTAuthData({
        user: data.user,
        isAuthenticated: true,
        isLoading: false,
      });
      infoViewActionsContext.fetchSuccess();
    } catch (error) {
      console.log(error);

      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      infoViewActionsContext.fetchError(error.response.data.message || 'Something went wrong');
    }
  };


  const logout = async () => {
    localStorage.clear();
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  const updateUser = async(userData)=>{
    console.log(userData)
    setJWTAuthData({
      user:userData,
      isLoading: false,
      isAuthenticated: true,
    })
  }

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          // signUpUser,
          signInUser,
          logout,
          updateUser
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
